import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import { SEO } from "components";
import { PodcastList, FeaturedPodcast } from "components/podcasts";
import { PageHeading } from "components/layout";
import useMenu from "state/useMenu";
/* Podcasts Page
============================================================================= */

const PodcastsPage = ({ data }) => {
	// Config
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	// Render
	return (
		<>
			<SEO title="Podcasts" />
			<PodcastList podcasts={data.allSanityPodcast.nodes} />
		</>
	);
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query PodcastsPageQuery {
		allSanityPodcast {
			nodes {
				description {
					_rawChildren(resolveReferences: { maxDepth: 10 })
				}
				special_label
				tagline
				hosts {
					description
					name
					role
					image {
						image {
							asset {
								localFile {
									childImageSharp {
										fluid(maxWidth: 750) {
											src
											srcSet

											srcWebp
											srcSetWebp
											base64
										}
									}
								}
							}
						}
						alt
					}
				}
				name
				image {
					image {
						asset {
							localFile {
								childImageSharp {
									fluid(maxWidth: 750) {
										src
										srcSet
										srcWebp
										srcSetWebp
										base64
									}
								}
							}
						}
					}
					alt
				}
			}
		}
	}
`;

/* Export
============================================================================= */

export default PodcastsPage;
